import { AppCommonProps } from 'types/app'
import React from 'react'
import { withI18n } from '~/hocs'

const CountDownEventItem = ({ backgroundColor, colorText, days, hours, minutes, seconds, renderDigits, premiumURL, windowWidth, backgroundColorNumber, imageLeft, imageRight }: AppCommonProps) => {
  return (
    <a href={premiumURL} className={`${backgroundColor} text-b d-none ac-d-md-block text-decoration-none`}>
      <span className="d-flex align-items-center justify-content-center mx-auto">
        <img
          src={imageLeft}
          alt=""
          className='lazy mw-100 d-none d-lg-block loaded'
          style={{ height: '70px' }}
        />
        <span className="d-flex align-items-end flex-wrap py-2 py-xl-0 mr-xl-3">
          <span className={`align-bottom lh-1 ac-heading-5 font-weight-bold mr-1 ${colorText}`}>終了まで</span>
          <span className={`ac-heading-3 lh-1 px-2 py-1 ${backgroundColorNumber} rounded mr-1 text-e02d7c`}>{days}</span>
          <span className={`align-bottom lh-1 ac-heading-5 font-weight-bold mr-2 ${colorText}`}>日</span>
          {renderDigits(hours)}
          <span className={`mb-2 font-weight-bold mr-1 ${colorText}`}>:</span>
          {renderDigits(minutes)}
          <span className={`mb-2 font-weight-bold mr-1 ${colorText}`}>:</span>
          {renderDigits(seconds)}
          <span className={`align-bottom lh-1 ac-heading-5 font-weight-bold ml-3 mr-1 ${colorText}`}>プレミアム会員が</span>
          <span className={`font-size-48 align-bottom font-weight-bold text-e02d7c mr-2`} style={{ lineHeight: '0.8', fontFamily: 'Noto Sans JP', fontSize: windowWidth < 789 ? '39px' : '48px' }}>35</span>
          <span className={`lh-1 align-bottom font-weight-bold text-e02d7c`} style={{
            fontSize: windowWidth < 789 ? '25px' : '28px',
          }}>%OFF</span>
        </span>
        <img
          src={imageRight}
          alt=""
          className={'lazy mw-100 d-none d-lg-block loaded image-right'}
          // className={`lazy mw-100 ${windowWidth < 1282 ? 'd-none' : '' } loaded`}
          style={{
            float: 'right',
            height: '60px',
          }}
        />
      </span>
    </a>
  )
}
export default withI18n(CountDownEventItem)
