import React from 'react'
import { withI18n } from '~/hocs'
import { redirectLogin, redirectSignUp, redirectSignUpCreator } from '~/utils'
import { NextPage } from 'next'
import { AppCommonProps } from 'types/app'
import { Button, Dropdown, DropdownButton, NavDropdown, NavLink, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Link from 'next/link'
import { faArrowRightFromBracket, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import config from '~/config'
import { signIn, signOut } from 'next-auth/react'
import { routes } from '~/router'
import DropdownApp from '../home/DropdownApp'
import HeaderPremium from './HeaderPremium'
import HeaderInfoSideBar from './HeaderInfoSideBar'
import HeaderNavBarCreator from './HeaderNavBarCreator'
import moment from 'moment'
import { useRouter } from 'next/router'

const HeaderRightSmall: NextPage<AppCommonProps> = ({t,lng, isAuthen, nextAuthStatus, isCreatorPages, onNavCollapseEnterCreator, isCollappseCreatorEnter, 
    isPremium, profile, setShowInfoSideBar, showInfoSideBar, nextAuthSession, showSettingAccountHeader, setShowSettingAccountHeader, setIsCollappseCreatorEnter }) => {
    const { asPath } = useRouter()
    const isUserDashboardPage = asPath == "/user/dashboard"
    
    return (
        <div className="header-right-small-screen d-flex align-items-center nav-btns">
            {
                !isAuthen || nextAuthStatus === 'authenticated'
                    ? (
                        // Not logged in as downloader
                        nextAuthStatus === 'authenticated'
                            ? (
                                // Logged in as creator
                                isCreatorPages
                                    ? (<>
                                        <div className="navbar-small-screen">
                                            <button type="button" className="navbar-toggler" style={{ left: "auto", height: "45px" }}>
                                                <span className="navbar-toggler-icon" onClick={onNavCollapseEnterCreator} />
                                            </button>
                                        </div>
                                        <HeaderNavBarCreator lng={lng} isCollappseCreatorEnter={isCollappseCreatorEnter} showSettingAccountHeader={showSettingAccountHeader} 
                                        setShowSettingAccountHeader={setShowSettingAccountHeader} setIsCollappseCreatorEnter={setIsCollappseCreatorEnter} />
                                    </>)
                                    : (<>
                                        {/* <DropdownApp /> */}
                                        <NavDropdown
                                            title={
                                                <>
                                                    {/* <img src={getAvatar(profile)} alt="avatar" width="40"/> */}
                                                    <div className="ml-2">
                                                        <span className="d-block">{nextAuthSession.user?.email.substring(0, 1) + '**********' + nextAuthSession.user?.email.substring(nextAuthSession.user?.email.length - 1, nextAuthSession.user?.email.length)}</span>
                                                    </div>
                                                </>
                                            }
                                            id="basic-nav-dropdown--"
                                            alignRight
                                            className="nav-item-profile hover-blue"
                                        >
                                            <NavDropdown.Item as={Button} className="p-0">
                                                <Link href={routes.creator.dashboard.href} locale={lng}>
                                                    <a className="d-block px-4 py-2 w-100 dropdown-item-logined">{t('my_page')}</a>
                                                </Link>
                                            </NavDropdown.Item>
                                            <NavDropdown.Divider />
                                            <NavDropdown.Item as={Button} onClick={() => signOut({ callbackUrl: routes.home.href })}>{t('logout')}</NavDropdown.Item>
                                        </NavDropdown>
                                    </>)
                            )
                            : (
                                // Not logged in
                                <>
                                
                                    <div className='button-login-mobile'>
                                        <div className='button-login-mobile-register-wrapper'>
                                            <DropdownButton
                                                id={'dropdown-button-drop-start'}
                                                drop="left"
                                                className='mr-1 button-login-mobile-register'
                                                title="会員登録"
                                            >
                                                <Dropdown.Item className="mr-3" variant="outline-primary" onClick={() => redirectSignUp(lng)}>{t('downloader_signup')}</Dropdown.Item>
                                                {
                                                    config.isEnableCreator
                                                        ? <Dropdown.Item className="mr-3" variant="outline-primary" onClick={() => redirectSignUpCreator(lng)}>{t('creator_signup')}</Dropdown.Item>
                                                        : (<></>)
                                                }
                                                <Dropdown.Item className="mr-3" variant="outline-primary" onClick={() => window.open(config.premiumUrl, '_blank')}>
                                                    <span className='d-flex align-items-center'>{t('go_premium')}<img className="mw-100 ml-2 lazy loaded " alt="プレミアム会員登録" width="20" src="https://jp.static.photo-ac.com/assets/img/responsive/ico_premium.png"
                                                        data-ll-status="loaded" /></span>
                                                </Dropdown.Item>
                                            </DropdownButton>
                                        </div>
                                        <div>
                                            <DropdownButton
                                                id={'dropdown-button-drop-start'}
                                                drop="left"
                                                className='button-login-mobile-login'
                                                title={t('login')}
                                            >
                                                <Dropdown.Item onClick={() => redirectLogin(lng)}>{t('download_user')}</Dropdown.Item>
                                                {
                                                    config.isEnableCreator
                                                        ? <Dropdown.Item onClick={() => signIn(undefined, { callbackUrl: routes.creator.dashboard.href })}>{t('creator')}</Dropdown.Item>
                                                        : (<></>)
                                                }
                                            </DropdownButton>
                                        </div>
                                    </div>
                                    <div className='d-none d-xl-flex'>
                                        <Button className="mr-3" variant="outline-primary" onClick={() => redirectSignUp(lng)}>{t('downloader_signup')}</Button>
                                        {
                                            config.isEnableCreator
                                                ? <Button className="mr-3" variant="outline-primary" onClick={() => redirectSignUpCreator(lng)}>{t('creator_signup')}</Button>
                                                : (<></>)
                                        }
                                        <NavDropdown
                                            title={<><Button><i className="fas fa-sign-in-alt" /> &nbsp; {t('login')}</Button></>}
                                            id="signin-nav-dropdown"
                                        >
                                            <NavDropdown.Item className="dropdown-item-login" onClick={() => redirectLogin(lng)}>{t('download_user')}</NavDropdown.Item>
                                            {
                                                config.isEnableCreator
                                                    ? <NavDropdown.Item className="dropdown-item-login" onClick={() => signIn(undefined, { callbackUrl: routes.creator.dashboard.href })}>{t('creator')}</NavDropdown.Item>
                                                    : (<></>)
                                            }
                                        </NavDropdown>
                                        <DropdownApp />
                                    </div>
                                    <div className='d-none d-lg-flex d-xl-none align-items-center' >
                                        {
                                            config.isEnableCreator
                                                ?
                                                <a className="mr-3 creator-signup-button" onClick={() => redirectSignUpCreator(lng)}>
                                                    <i className="fa-solid fa-camera" style={{ fontSize: "14px" }}></i><span className="ml-1">{t('creator_signup_sort')}</span>
                                                </a>
                                                : (<></>)
                                        }
                                        <Button className="mr-3" variant="outline-primary" onClick={() => redirectSignUp(lng)}>{t('downloader_signup_short')}</Button>
                                        <NavDropdown
                                            title={<><Button><i className="fas fa-sign-in-alt" /> &nbsp; {t('login')}</Button></>}
                                            id="signin-nav-dropdown">
                                            <NavDropdown.Item onClick={() => redirectLogin(lng)}>{t('download_user')}</NavDropdown.Item>
                                            {
                                                config.isEnableCreator
                                                    ? <NavDropdown.Item onClick={() => signIn(undefined, { callbackUrl: routes.creator.dashboard.href })}>{t('creator')}</NavDropdown.Item>
                                                    : (<></>)
                                            }
                                        </NavDropdown>
                                        <DropdownApp />
                                    </div>
                                    <div className='d-none d-sm-flex d-lg-none' id="signup-nav-dropdown-wrapper" >
                                        <NavDropdown title={t('free_signup')} id="signup-nav-dropdown">
                                            <NavDropdown.Item onClick={() => redirectSignUp(lng)}>{t('downloader_signup')}</NavDropdown.Item>
                                            {
                                                config.isEnableCreator
                                                    ? <NavDropdown.Item href={`/${lng}/${routes.creator.reg.href}`}>{t('creator_signup')}</NavDropdown.Item>
                                                    : (<></>)
                                            }
                                            <NavDropdown.Item className="dropdown-item-login" onClick={() => window.open(config.premiumUrl, '_blank')}>
                                            <span className='d-flex align-items-center'>{t('go_premium')}<img className="mw-100 ml-2 lazy loaded " alt="プレミアム会員登録" width="20" src="https://jp.static.photo-ac.com/assets/img/responsive/ico_premium.png"
                                                        data-ll-status="loaded" /></span>
                                            </NavDropdown.Item>
                                        </NavDropdown>
                                        {/* <Button onClick={() => redirectLogin(lng)}>{t('login')}</Button> */}
                                        <DropdownButton
                                            id={'dropdown-button-drop-start'}
                                            // drop="start"
                                            title={t('login')}
                                            className="button-login-medium-green"
                                        >
                                            <Dropdown.Item onClick={() => redirectLogin(lng)}>{t('download_user')}</Dropdown.Item>
                                            {
                                                config.isEnableCreator
                                                    ? <Dropdown.Item onClick={() => signIn(undefined, { callbackUrl: routes.creator.dashboard.href })}>{t('creator')}</Dropdown.Item>
                                                    : (<></>)
                                            }
                                        </DropdownButton>
                                        <DropdownApp />
                                    </div>
                                </>
                            )
                    )
                    : (
                        // Logged in as downloader
                        <div className='d-flex align-items-center'>
                            {
                                            config.isEnableCreator && !isUserDashboardPage
                                                ? <a className="mr-3 creator-signup-button" onClick={() => redirectSignUpCreator(lng)}>
                                                    {/* <i className="fa-solid fa-camera" style={{ fontSize: "14px" }}></i><span className="ml-1">{t('creator_signup')}</span> */}
                                                    <OverlayTrigger
                                                        key={'top'}
                                                        placement={'bottom'}
                                                        overlay={
                                                            <Tooltip id={`tooltip-top`} style={{fontSize: "13px"}}>
                                                            {t('creator_signup')}
                                                            </Tooltip>
                                                        }
                                                        >
                                                        <span>
                                                            <i className="fa-solid fa-camera" style={{ fontSize: "14px" }}></i><span className="ml-1">動画投稿する</span>
                                                        </span>
                                                    </OverlayTrigger>
                                                </a>
                                                : (<></>)
                                        }
                            {
                                profile?.premium_info?.pause_end_date && moment(profile?.premium_info?.pause_end_date, "YYYY-MM-DD").valueOf() > moment().valueOf() ? <></> : (!isPremium && <NavLink className="nav-link-premium ac-btn mr-2 button-premium" href={config.premiumUrl} target="_blank">
                                    <img className="mw-100 mr-2 lazy loaded " alt="プレミアム会員登録" width="20" src="https://jp.static.photo-ac.com/assets/img/responsive/ico_premium.png"
                                        data-ll-status="loaded" />{t('go_premium')}
                                </NavLink>)
                            }
                            <DropdownApp />
                            <HeaderPremium isPremium={isPremium} profile={profile} setShowInfoSideBar={setShowInfoSideBar}
                                showInfoSideBar={setShowInfoSideBar} />
                            {showInfoSideBar && <HeaderInfoSideBar setShowInfoSideBar={setShowInfoSideBar} isPremium={isPremium} profile={profile} lng={lng} />}
                        </div>
                    )
            }
        </div>
    )
}

export default withI18n(HeaderRightSmall)