import React, { useEffect, useState } from 'react'
import Countdown from 'react-countdown'
import { withI18n } from '~/hocs'
import { NextPage } from 'next'
import { AppCommonProps } from 'types/app'
import { Container } from 'react-bootstrap'
import config from '~/config'
import moment from 'moment-timezone'
import CountDownEventSmallGreen from './CountDownEventSmallGreen'
import CountDownEventItem from './CountDownEventItem'

// eslint-disable-next-line no-empty-pattern
const CountDownEvent: NextPage<AppCommonProps> = ({ }) => {
  const targetDate = moment.tz('2025-02-01 00:00:00', 'Asia/Tokyo').valueOf()
  // Lấy ngày và giờ hiện tại theo múi giờ Nhật
  const now = moment().tz('Asia/Tokyo')
  const startDate = moment.tz('2025-01-24 00:00:00', 'Asia/Tokyo')
  const endDate = moment.tz('2025-02-01 00:00:00', 'Asia/Tokyo')

  const [windowWidth, setWindowWidth] = useState(0)
  const backgroundColor = 'bg-ffe6ee'
  const backgroundColorNumber = 'bg-ffffff'
  const colorText = 'text-602f13'
  const imageLeft = `${process.env.ASSET_PREFIX}/asset/image/banner/TOP_header_left_01_2025.png`
  const imageRight = `${process.env.ASSET_PREFIX}/asset/image/banner/TOP_header_right_01_2025.png`

  const renderDigits = (value) => {
    const paddedValue = value.toString().padStart(2, '0')
    const digits = paddedValue.split('')
    if (windowWidth <= 767) {
      return <span className={`ac-heading-3 lh-1`}>{digits}</span>
    } else {
      return digits.map((digit, index) => (
        <span key={index} className={`ac-heading-3 lh-1 px-2 py-1 ${backgroundColorNumber} text-e02d7c rounded mr-1`}>{digit}</span>
      ))
    }
  }

  useEffect(() => {
    // Kiểm tra nếu mã đang chạy trong trình duyệt
    if (typeof window !== 'undefined') {
      setWindowWidth(window.innerWidth)

      function handleResize() {
        setWindowWidth(window.innerWidth)
      }

      window.addEventListener('resize', handleResize)

      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [])
  const renderer = ({ days, hours, minutes, seconds }: any) => {
    return (
      <>
        {now.isBetween(startDate, endDate) && <div className={`${backgroundColor}`}>
          <>
            <CountDownEventItem backgroundColor={backgroundColor} colorText={colorText} days={days} hours={hours} minutes={minutes} seconds={seconds}
              renderDigits={renderDigits} premiumURL={config.premiumUrl} windowWidth={windowWidth} backgroundColorNumber={backgroundColorNumber} imageLeft={imageLeft} imageRight={imageRight} />
            {/* Small green */}
            <CountDownEventSmallGreen backgroundColor={backgroundColor} colorText={colorText} days={days} hours={hours} minutes={minutes} seconds={seconds}
              renderDigits={renderDigits} premiumURL={config.premiumUrl} />
            {/* End Small green */}
          </>
        </div>}
      </>
    )
  }

  return <Countdown date={targetDate} renderer={renderer} autoStart={true} />
}

export default withI18n(CountDownEvent)
